import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Divider, Snackbar, Switch, withStyles } from '@material-ui/core';
import styles from './manage-ads.style';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from '@orijinworks/frontend-commons';
import useManageAdsHook from './useManageAdsHook';
import { AdsList, AdsUpload } from './components';
import MuiAlert from '@material-ui/lab/Alert';
import { SNACK_BAR_ERROR, SNACK_BAR_TIMEOUT } from '../../services/constants';

/**
 * Component to manage ads
 *
 * @param {object} props - The props object
 * @returns {JSX.Element} - The JSX element
 */
const ManageAds = ({ classes }) => {
  const {
    isEnabled,
    showAdsToggleLoader,
    adsList,
    error,
    handleAdsVisibility,
    handleAdsUpload,
    hasAds,
    handleAdDelete,
    toggleError,
  } = useManageAdsHook();

  return (
    <Box className={classes.root}>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={SNACK_BAR_TIMEOUT}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          toggleError(null);
        }}
      >
        <MuiAlert severity={SNACK_BAR_ERROR} elevation={6} variant="filled" className={classes.alert}>
          {error}
        </MuiAlert>
      </Snackbar>

      <Box className={classes.header}>
        <Typography data-testid="manage-ads-title" variant="h1" color="secondary">
          Entertainment Center Ads
        </Typography>
        <Divider className={classes.divider} />
      </Box>
      <Box className={classes.content}>
        {/* BEGIN: SWITCH AREA */}
        <Box className={classes.switchArea}>
          <Box className={classes.switchText}>
            <Typography data-testid="switch-area-title" className={classes.subtitle}>
              Movie Advertising Feature
            </Typography>
            <Typography data-testid="switch-area-description" className={classes.description}>
              Enabling this feature will display the active uploaded video to learners on all entertainment center movies.
            </Typography>
          </Box>

          {showAdsToggleLoader ? (
            <CircularProgress color="primary" />
          ) : (
            <Switch
              classes={{
                root: classes.switchRoot,
                switchBase: classes.switchBase,
                thumb: classes.switchThumb,
                track: isEnabled ? classes.switchTrackOn : classes.switchTrackOff,
                checked: classes.switchChecked,
              }}
              inputProps={{ 'aria-label': 'Enable/Disable Movie Ads' }}
              onChange={handleAdsVisibility}
              checked={isEnabled}
              name="enableMovieAds"
              icon={<CancelRoundedIcon className={classes.swicthThumbIcon} />}
              checkedIcon={<CheckCircleIcon className={classes.switchThumbIcon} />}
            />
          )}
        </Box>
        {/* END: SWITCH AREA */}

        <AdsList ads={adsList} hasAds={hasAds} onAdDelete={handleAdDelete} />
        {!hasAds() && <AdsUpload onUpload={handleAdsUpload} toggleError={toggleError} />}
      </Box>
    </Box>
  );
};

ManageAds.propTypes = {
  /**
   * The classes object is used to access the styles provided by withStyles
   */
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageAds);

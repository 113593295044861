import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import React from 'reactn';
import styles from './learner-management.component.style';
import useLearnerManagementComponentHook from './useLearnerManagementComponentHook';
import { Autocomplete, Modal, SnackBar, Typography } from '@orijinworks/frontend-commons';

const LearnerManagementComponent = ({
  activeTab,
  locationId,
  classes,
  isDataRefresh,
  setDataRefresh,
  shouldExportCallHistory,
  setShouldExportCallHistory,
}) => {
  const {
    tableColumns,
    isLoading,
    pageSize,
    openSnackbar,
    snackbarAutoHideDuration,
    handleCloseSnackbar,
    snackbarMessage,
    isSuccessSnackbar,
    getLearnersList,
    tableRef,
    showScheduleAssignmentModal,
    setShowScheduleAssignmentModal,
    showRerecordConfirmationModal,
    setShowRerecordConfirmationModal,
    scheduleList,
    selecetedSchedule,
    submitScheduleChange,
    submitRerecordVoiceProfile,
    snackbarConfig,
    setSnackbarConfig,
    selectedLearner,
    disableActionButtons,
    handleScheduleChange,
  } = useLearnerManagementComponentHook(
    activeTab,
    locationId,
    classes,
    isDataRefresh,
    setDataRefresh,
    shouldExportCallHistory,
    setShouldExportCallHistory
  );

  const tableColumnsRef = React.useRef(tableColumns);

  return (
    <>
      <SnackBar
        isShown={snackbarConfig.showSnackbar}
        type={snackbarConfig.snackbarType}
        onClose={() => {
          setSnackbarConfig((prev) => ({ ...prev, showSnackbar: false }));
        }}
      >
        {snackbarConfig.message}
      </SnackBar>

      {locationId ? (
        <MaterialTable
          tableRef={tableRef}
          data-id="contacts-table"
          columns={tableColumnsRef.current}
          title={''}
          data={getLearnersList}
          options={{
            filtering: true,
            pageSize: pageSize,
            search: false,
            thirdSortClick: false,
            debounceInterval: 1000,
          }}
        />
      ) : null}
      <Snackbar id="snackbar" open={openSnackbar} autoHideDuration={snackbarAutoHideDuration} onClose={handleCloseSnackbar}>
        <MuiAlert
          id="snackbar-alert"
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={isSuccessSnackbar ? 'success' : 'error'}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      {showScheduleAssignmentModal && (
        <Modal
          id="schedule-assignment-modal"
          open={showScheduleAssignmentModal}
          onClose={() => {
            setShowScheduleAssignmentModal(false);
          }}
          onContinue={submitScheduleChange}
          continueButtonProps={{ disabled: disableActionButtons }}
          actionBtnText="Apply"
          cancelBtnText="Cancel"
          heading="Change Schedule"
          styles={{
            mainWrapperStyles: {
              height: 'auto',
            },
          }}
        >
          <Typography id="schedule-change-label" variant="body1" className={classes.scheduleAutoCompleteTypography}>
            You are about to change schedule for <b>{selectedLearner.learnerName}</b>. Please select what schedule you would
            like to assign.
          </Typography>

          <Autocomplete
            id="schedule-assignment-autocomplete"
            className={classes.scheduleAutoComplete}
            value={selecetedSchedule}
            label="Select Schedule"
            options={scheduleList}
            getOptionLabel={(option) => option.scheduleName}
            renderOption={(props, option) => (
              <li {...props}>
                {option.isDefault ? <b>{`*Default Schedule (${option.scheduleName})`}</b> : option.scheduleName}
              </li>
            )}
            onChange={handleScheduleChange}
          />
        </Modal>
      )}

      {showRerecordConfirmationModal && (
        <Modal
        id="re-record-confirmation-modal"
        open={showRerecordConfirmationModal}
        onClose={() => {
          setShowRerecordConfirmationModal(false);
        }}
        continueButtonProps={{ disabled: disableActionButtons }}
        onContinue={submitRerecordVoiceProfile}
        actionBtnText="Confirm"
        cancelBtnText="Cancel"
        heading="Re-record Voice ID"
        styles={{
          mainWrapperStyles: {
            height: 'auto',
          },
        }}
      >
        <Typography id="schedule-change-label" variant="body1" className={classes.scheduleAutoCompleteTypography}>
          Are you sure you want to require <b>{selectedLearner.learnerName}</b> to re-record their Voice ID?
          <br/>
          <br/>
          This action will prompt the resident to create a new Voice ID before they can make calls. The resident will receive a notification to complete this process.
        </Typography>

      </Modal>
      )}
    </>
  );
};
export default withStyles(styles)(LearnerManagementComponent);

import { fontFamily, fontSize, fontWeight } from "styled-system";

export default (theme) => ({
  header: {
    width: '696px',
    padding: '0px 0px 12px 0px'
  },
  headerText: {
    fontSize: 32,
    fontWeight: '800'
  },
  body: {
    padding: '15px 0px'
  },
  callDetailsSections: {
    display: 'flex',
    height: '138px',
    marginTop: 12
  },
  textGreen: {
    color: theme.palette.colors.greenMain,
    marginTop: '5px',
  },
  groupDetatilBox: {
    border: `1px solid ${theme.palette.greyScale.greyBackground}`,
    borderRadius: 12,
    paddingLeft: 15,
    marginRight: 10,
    padding: '16px 24px 16px 24px',
    flex: 1,
    height: 90
  },
  displayNameLabel: {
    color: theme.palette.button.blue.dark,
    fontWeight: 'bold',
    fontSize: 20,
  },
  learnerText: {
    color: theme.palette.primary.mediumBlue,
    fontWeight: 700,
  },
  visitorText: {
    color: theme.palette.primary.mediumBlue,
    fontWeight: 700,
  },
  inCallActionsText: {
    color: theme.palette.button.blue.dark,
    fontWeight: 800,
    paddingTop: 15,
    fontSize: 30
  },

  footer: {
    padding: '0px 20px 25px'
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leaveBtn: {
    background: theme.palette.primary.blue.background,
    borderRadius: 35,
    color: 'white',
    fontWeight: 'bold',
    marginTop: 10,
    '&:hover': {
      background: theme.palette.primary.blue.background
    }
  },
  callActionsBtnGrid: {
    border: `1px solid ${theme.palette.greyScale.greyBackground}`,
    marginTop: '8px',
    borderRadius: 12,
    padding: '6px 10px 6px 10px'
  },
  speakButton: {
    borderRadius: '50',
    border: `1px solid ${theme.palette.button.blue.default}`,
    color: theme.palette.button.blue.default,
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16
  },
  blockButton: {
    borderColor: 'black',
    borderRadius: '50',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16
  },
  terminateButton: {
    color: theme.palette.text.red,
    borderRadius: '50',
    border: `1px solid ${theme.palette.colors.red.deep}`,
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16
  },
  terminateCallSection: {
    width: '696px',
    padding: '16px 24px 16px 24px',
    border: `2px solid ${theme.palette.colors.red.deep}`,
    borderRadius: '20px',
    margin: '0px auto'
  },
  terminateCallTitle: {
    fontWeight: 700,
    fontSize: 20,
    width: 465,
    paddgingTop: 12,
    color: theme.palette.text.red
  },
  terminateCallDescription: {
    fontWeight: 430,
    fontSize: 16,
    width: 501,
    paddgingTop: 14
  },

  terminateCallButtonsSection: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '20px',
    marginLeft: '3px',
  },
  terminateCallButton: {
    background: theme.palette.text.red,
    width: '150px',
    height: '44px',
    padding: '8px',
    borderRadius: '50px',
    marginRight: '10px',
    textTransform: 'capitalize',
    color: theme.palette.primary.white,
    '&:hover': {
      background: theme.palette.text.red,
    }
  },
  phoneForwardedIcon: {
    color: theme.palette.colors.grey60,
    marginRight: '5px',
    '&:hover': {
      background: 'none !important',
    }
  },
  headphonesBatteryRounded: {
    color: 'white',
    background: theme.palette.primary.blue.main,
    padding:8,
    borderRadius: 100
  },
  root: {
    '& .MuiDrawer-paper': {
      padding: '0px 12px 16px 12px !important',
      overflow: 'none',
      borderLeft: '10px solid',
      borderImage: `linear-gradient(to bottom left, ${theme.palette.colors.accentTeal} 9.18%, ${theme.palette.primary.deep} 90.82%) 1`,
    },
  },
  callInsightText : {
    color: theme.palette.colors.grey10,
    marginTop: '12px',
    marginLeft: '16px',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
  },
  voiceInsightChip: {
    marginTop: '12px'
  },
  callInsightTextTitle: {
    fontWeight: '700',
    fontFamily: 'Inter',
    marginTop: '12px',
    fontSize: '24px',
    color: 'var(--navy-main)'
  },
  drawer: {
    padding: '12px 24px 32px 24px',
    width: '744px',
    marginTop: '16'
  },

  closeIcon: {
    color: theme.palette.primary.blue.main,
  },
  drawerContent: {
    width: '744px',
    padding: '12px 24px 32px 20px',
    gap: '16px',
    opacity: '0px',
    height: 'auto',
  },
  drawerHeader: {
    padding: '16px 0px 0px 0px',
  },
  notificationPanel: {
    borderRadius: 8,
    color: theme.palette.primary.blue.main,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    background: theme.palette.primary.paleBlue,
    padding: '12px',
    fontWeight: 500
  },
  cancelIcon: {
    paddingRight: 10,
    fontSize: '1.2rem'
  },
  flaggedKeywordsSection: {
    marginTop: theme.spacing(2),
  },

});
